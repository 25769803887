<template>
  <div class="newscl">
    <!-- header组件     -->
    <vheader :navId="navId"> </vheader>
    <!-- header组件     -->

    <!-- banner组件 -->
    <!-- <banner :navName="navName" :navUrl="navUrl"></banner> -->
    <div class="banner-box">
      <div class="banner-img"></div>
      <div class="banner-nav">
        <div class="banner-nav-content">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <span><i class="el-icon-location"></i>当前位置：</span>
            <el-breadcrumb-item :to="{ path: 'index' }" title="业务入口">网站首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: 'mediaCoverage' }" title="媒体报道">媒体报道</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: 'newscl1' }" title="新闻详情">新闻详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
    </div>
    <!-- banner组件 -->
    <div class="newscl_w">
      <div class="newsContentBoxLeft">
        <h1 class="newsContentBoxLeftTitle" id="title">解读恒安康泰之直击“三化”服务优势</h1>
        <p class="newsContentBoxLeftTime">
          <span>发布时间：2021-06-21 15:08:13</span><span>阅读：244</span><span>来自：恒安康泰新闻中心</span><span>编辑：刘春梅</span>
        </p>
        <hr class="newsContentBoxLeftFenxian">
        <div id="frameContent">
          <p style="text-indent: 2em; line-height: 1.5em;"><br></p>
          <p style="text-indent: 2em; line-height: 1.5em;"><span
              style="text-indent: 32px;">为积极响应国家补充医疗政策，让每一位大众拥有赴京就医的便捷通道，<span
                style="text-indent: 32px;">恒安康泰</span>致力于为大众带来便捷、细致的服务，实现赴京就医无忧愁。公司创始人</span><span
              style="text-indent: 32px;">以大众所需为出发点，</span><span style="text-indent: 32px;">集团队智慧为一体，为会员</span><span
              style="text-indent: 32px;">提供有保障、可信赖的“三化”优势服务。</span></p>
          <p style="text-indent: 2em; line-height: 1.5em;"><br></p>
          <p style="text-align: center;"><img src="../../assets/img/mediaCoverage/1624259226.jpg" title="1624259226.jpg"
              alt="医疗.jpg"></p>
          <p style="text-indent: 2em;"><br></p>
          <p><strong>一、精准化服务</strong></p>
          <p><strong><br></strong></p>
          <p style="text-indent: 2em; line-height: 1.5em;">
            恒安康泰(北京)医疗健康科技专业服务于企事业单位的在职员工,对同一地区,同类行业,不同年龄需赴京就诊的患者情况有深入的了解和多年的服务经验,可以更加精准的为职工提供细致入微的医疗健康咨询和管理服务。</p>
          <p><br></p>
          <p><strong>二、本地化服务</strong></p>
          <p><strong><br></strong></p>
          <p style="text-indent: 2em; line-height: 1.5em;">
            恒安康泰(北京)医疗健康科技扎根北京,对北京各大医院的医疗专业特色和赴院就诊流程熟悉,本地优势明显。可以为合作单位在职职工会员的赴京医疗行程做细致入微的本地化安排,使会员们赴京前轻松准备、赴京后高枕无忧。</p>
          <p><br></p>
          <p><strong>三、个性化手段</strong></p>
          <p><strong><br></strong></p>
          <p style="text-indent: 2em; line-height: 1.5em;">
            恒安康泰(北京)医疗健康科技运用国际化的数据统计及客户管理软件,切实形成了全方位的个体化突出的客户医疗健康管理服务体系,安全、有效的为有赴京诊治需求的每一位会员提供了专属的医疗健康信息储备和保障。</p>
          <p><br></p>
          <p style="text-indent: 2em;"><span
              style="text-indent: 32px;">聚焦北方地区大众医疗健康，恒安康泰（北京）医疗健康科技有限责任公司专注服务于企事业单位在职员工,提供优质、全面、便捷的赴京诊疗导医服务。全方位提供会员个人健康咨询、北京地区知名医院推荐、协助会员进行北京地区名医就诊挂号、专家预约、手术及复诊安排。</span>
          </p>
          <p style="text-indent: 2em;"><span style="text-indent: 32px;"><br></span></p>
          <p style="text-indent: 2em;"><span style="text-indent: 32px;">责任编辑：刘春梅</span></p>
          <p style="text-indent: 2em;"><span style="text-indent: 32px;">新闻审核：恒安康泰新闻中心</span></p>
        </div>

      </div>
      <div class="listNewsRight">
        <h2 class="stateNewsRighth2">健康热点资讯</h2>
        <hr class="stateNewsRight_h2_hr" />
        <h3 class="stateNewsRighth3">
          <a href="" title="国务新闻">国务新闻</a>
        </h3>
        <hr class="stateNewsRight_h3_hr" />
        <ul class="stateNewsRightUl">
          <li class="stateNewsRightAdverts" v-for="item in rilist1[0]" :key="item.id">
            <i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
          </li>
        </ul>
        <h2 class="stateNewsRighth3">恒安图文</h2>
        <hr class="stateNewsRight_h3_hr" />
        <!-- <a href=""><img class="guowuImg" src="../../assets/img/mediaCoverage/guowu1.jpg" alt="" title="恒安康泰医疗" /></a> -->
        <h3 class="stateNewsRighth3">
          <a href="" title="名医出诊">名医出诊</a>
        </h3>
        <hr class="stateNewsRight_h3_hr" />
        <ul class="stateNewsRightUl">
          <li class="stateNewsRightAdverts" v-for="item in rilist1[1]" :key="item.id">
            <i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
          </li>
        </ul>
        <h3 class="stateNewsRighth3">
          <a href="" title="健康养生">健康养生</a>
        </h3>
        <hr class="stateNewsRight_h3_hr" />
        <ul class="stateNewsRightUl">
          <li class="stateNewsRightAdverts" v-for="item in rilist1[2]" :key="item.id">
            <i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
          </li>
        </ul>
        <h2 class="stateNewsRighth3">恒安图文</h2>
        <hr class="stateNewsRight_h3_hr" />
        <a href=""><img class="guowuImg" src="../../assets/img/mediaCoverage/guowu2.jpg" alt="" title="恒安康泰医疗" /></a>
        <h3 class="stateNewsRighth3">
          <a href="" title="健康资讯">健康资讯</a>
        </h3>
        <hr class="stateNewsRight_h3_hr" />
        <ul class="stateNewsRightUl">
          <li class="stateNewsRightAdverts" v-for="item in rilist1[3]" :key="item.id">
            <i></i><a :href="item.lishref" :title="item.listit">{{ item.listit }}</a>
          </li>
        </ul>
      </div>
    </div>
    <vfooter></vfooter>
    <GoTop />
  </div>
</template>
<script>
// import banner from "../../components/banner.vue";
import vheader from "../../components/vheader.vue";
import vfooter from "../../components/vfooter.vue";
import GoTop from "@/components/GoTop.vue";
export default {
  name: 'newscl14',
  data() {
    return {
      navName: '新闻详情',
      navUrl: 'newscl14',
      navId: 5,
      rilist1: [
        [
          { listit: "2020年全国居民健康素养水平达23.15%", lishref: "#" },
          { listit: "癌症治疗新希望！我国首个自主研发...", lishref: "#" },
          { listit: "国家卫健委出台人感染动物源性流感...", lishref: "#" },
          { listit: "国家医保局发文，明确推进门诊费用...", lishref: "#" },
          { listit: "国家卫健委：加强儿童中医药服务 推...", lishref: "#" },
          { listit: "这家西南的医院用高品质医疗服务，建...", lishref: "#" },
          { listit: "“十四五”残疾人康复服务蓝图绘就", lishref: "#" },
          { listit: "《2021医生互联网诊疗行为洞察报告》...", lishref: "#" },
          { listit: "六大方面布局！国办发文推动公立医院...", lishref: "#" },
          { listit: "七大城市用户调研指出：智慧医疗未来...", lishref: "#" },
        ],
        [
          { listit: "中国人民解放军总医院院士合集（部分）", lishref: "#" },
          { listit: "U医公益行启动，让泌尿疾病患者在基层...", lishref: "#" },
          { listit: "第十八期“院士+”西部儿科医师培训班座...", lishref: "#" },
          { listit: "福建首例机器人无腹部切口大肠癌手术在...", lishref: "#" },
          { listit: "多位专家赴湖北十堰参加燃气爆炸紧急医...", lishref: "#" },
          { listit: "张楠：阿尔茨海默病患者护理更需专业指导", lishref: "#" },
          { listit: "第一届智慧医疗与医学人文高峰论坛暨医...", lishref: "#" },
          { listit: "提醒身边长痣的朋友，出现以下五种症状...", lishref: "#" },
          { listit: "“疫”路同行的夫妻档", lishref: "#" },
          { listit: "与北京社团合作，中国西部城市乌兰察布...", lishref: "#" },
        ],
        [
          { listit: "水培蔬菜社交网络走红 专家：较土培更...", lishref: "#" },
          { listit: "山药的做法 山药的功效与作用", lishref: "#" },
          { listit: "发青的鸡蛋黄致癌？ 变绿部分无毒，可...", lishref: "#" },
          { listit: "经常失眠，可能是体内缺什么？3种营养...", lishref: "#" },
          { listit: "你知道健康吃饭的黄金标准吗？", lishref: "#" },
          { listit: "5大习惯能『防癌』，很多人都忽视了", lishref: "#" },
          { listit: "每餐4-6小时的间隔期最符合人体消化需...", lishref: "#" },
          { listit: "晨起一杯水别这么喝，风险太大...教你...", lishref: "#" },
          { listit: "心脏有问题，为什么医生还建议让我多运...", lishref: "#" },
          { listit: "缺铁性贫血，补就完事？补血食物别踩坑", lishref: "#" },
        ],
        [
          { listit: "疾控专家邵一鸣对我国目前疫苗功效给出解读", lishref: "#" },
          {
            listit: "馒头、米饭哪个更容易升血糖？一对比，3餐...",
            lishref: "#",
          },
          { listit: "手抖也能通过外科手术治愈啦", lishref: "#" },
          { listit: "身体血糖高 多喝水好还是少喝水好？看完...", lishref: "#" },
          { listit: "练起来 打太极拳能减“游泳圈”", lishref: "#" },
          { listit: "【科学就医我行动】陈勇皮科问答 | 10.皮...", lishref: "#" },
          { listit: "维生素C有什么功效和副作用？", lishref: "#" },
          {
            listit: "“提高免疫力”，被过度夸大化，免疫平衡比“...",
            lishref: "#",
          },
          { listit: "胃癌如何选择最合适疗方案？CA最新刊文指...", lishref: "#" },
          { listit: "九价HPV疫苗为何“一针难求”", lishref: "#" },
        ],
      ],
    };
  },
  components: {
    // banner,
    vheader,
    vfooter,
    GoTop,
  },
};
</script>
<style lang="less">
@media screen and (min-width:750px) {
  .banner-box {
    .banner-img {
      height: 280Px;
      background: url(../../assets/img/banner/banner01.jpg) no-repeat center top / 100%;
    }

    .banner-nav {
      background-color: #f6f8fb;

      .banner-nav-content {
        width: 1200Px;
        margin: auto;

        .el-breadcrumb {
          line-height: 70Px;
        }

        .el-breadcrumb__inner.is-link {
          font-weight: unset;
          color: #333;
          cursor: pointer;

          &:hover {
            color: #093C6C;
            text-decoration: underline;
          }
        }

        span {
          display: flex;
          align-items: center;

          .el-icon-location {
            color: #093C6C;
            font-size: 20Px;
            margin-right: 12Px;
          }

          float: left;
          color:#5a5a5a;
        }
      }
    }
  }
}

@media screen and (max-width:750px) {
  .banner-box {
    .banner-img {
      height: 110px;
      background: url(../../assets/img/banner/banner01.jpg) no-repeat center top / 200%;
    }

    .banner-nav {
      background-color: #f6f8fb;

      .banner-nav-content {
        width: 95%;
        margin: auto;

        .el-breadcrumb {
          line-height: 35px;
        }

        .el-breadcrumb__inner.is-link {
          font-weight: unset;
          color: #333;
          cursor: pointer;

          &:hover {
            color: #093C6C;
            text-decoration: underline;
          }
        }

        span {
          display: flex;
          align-items: center;

          .el-icon-location {
            color: #093C6C;
            font-size: 19px;
            margin-right: 2px;
          }

          float: left;
          color:#5a5a5a;
        }
      }
    }
  }
}
</style>